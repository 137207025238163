export const validateOnKeyDownInput = (e) => {
  const regex = /[a-zA-Z0-9æøåäëïöüÆØÅÄËÏÖÜ]/;
  if (!regex.test(e.key)) e.preventDefault();
};

export const validateOnPasteInput = (e) => {
  const regex = /^[a-zA-Z0-9æøåäëïöüÆØÅÄËÏÖÜ]{2,9}$/;
  const text = e.clipboardData.getData('Text');
  if (!regex.test(text)) e.preventDefault();
};

export const validateOnSubmit = (vehicleReg) => {
  const regex = /^[a-zA-Z0-9æøåäëïöüÆØÅÄËÏÖÜ]{2,9}$/;
  return regex.test(vehicleReg);
};
