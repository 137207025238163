import {Fragment, useEffect, useState} from 'react';
import {Listbox, Transition} from '@headlessui/react';
import {SelectorIcon} from '@heroicons/react/solid';
import TermsModal from '../modals/TermsModal';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {parkFromTablet, plateCanPark, reset, tabletInfo} from '../confirm/store/informationSlice';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {showMessage} from '../features/store/messageSlice';
import overviewIcon from './../../Assets/apcoa_overview_icon.png';
import ParkingOverviewModal from '../modals/ParkingOverviewModal';
import { validateOnKeyDownInput, validateOnPasteInput, validateOnSubmit } from '../../utils/validations';
import {countryFlags} from "../features/CountryFlags";


export default function Welcome() {
    const navigate = useNavigate();
    const [selected, setSelected] = useState(countryFlags[0]);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [canContinue, setCanContinue] = useState(false);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {uid} = useParams();
    const tablet = useSelector(({information}) => information.information.parking);
    const [licensePlate, setLicensePlate] = useState('');
    const [delayedStartDate, setDelayedStartDate] = useState('');
    const [delayedEndDate, setDelayedEndDate] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [keyboardIsShown, setKeyboardIsShown] = useState(false);
    const initialScreenSize = window.innerHeight;
    window.addEventListener('resize', handleResize);
    let isPhone = initialScreenSize <= 766;

    useEffect(() => {
        dispatch(reset());
    }, [])

    useEffect(() => {
        let timeout;
        if (licensePlate || phoneNumber || acceptTerms || delayedStartDate || delayedEndDate) {
            timeout = setTimeout(() => {
                setLicensePlate('');
                setPhoneNumber('');
                setAcceptTerms(false);
                setDelayedStartDate('')
                setDelayedEndDate('')
            }, [30 * 1000]);
        }

        if (!licensePlate && !phoneNumber && !acceptTerms && !delayedStartDate && !delayedEndDate) clearTimeout(timeout);

        return () => {
            if (timeout)
                clearTimeout(timeout);
        };
    }, [acceptTerms, licensePlate, phoneNumber, delayedStartDate, delayedEndDate, uid]);

    const [showParkingOverview, setShowParkingOverview] = useState(false);

    function handleChange(event) {
        const input = event.target;
        setLicensePlate(input.value.toUpperCase());
    }

    function handleResize() {
        if (initialScreenSize > window.innerHeight && !keyboardIsShown) {
            setKeyboardIsShown(true);
        } else {
            setKeyboardIsShown(false);
        }
    }

    useEffect(() => {
        if (tablet.Uid == null && uid != null) {
            dispatch(tabletInfo(uid));
            // 22668573-b7d0-42a8-b2dd-68c5109dbe49
        }
    }, [dispatch, tablet.Uid, uid]);
    useEffect(() => {
        if (acceptTerms && licensePlate !== '') {
            setCanContinue(true);
        } else {
            setCanContinue(false);
        }
    }, [acceptTerms, licensePlate]);


    function Continue() {
        if (new Date(delayedStartDate) < new Date(new Date().getTime() - 1000 * 60 * 5)) {
            dispatch(showMessage({variant: 'error', message: 'Startdato/-tidspunkt kan ikke ligge før her og nu tidspunktet'}));
            return;
        }

        if (!uid) {
            dispatch(showMessage({variant: 'error', message: 'Fejl i link.'}));
            return;
        }

        if (licensePlate.trim().length === 0 || !validateOnSubmit(licensePlate)) {
            dispatch(showMessage({variant: 'error', message: 'Registreringsnummer er ikke gyldigt'}));
            return;
        }

        if (delayedStartDate && delayedEndDate) {
            const parsedStartDateTime = Date.parse(delayedStartDate);
            const parsedEndDateTime = Date.parse(delayedEndDate);
            if (parsedEndDateTime < parsedStartDateTime) {
                dispatch(showMessage({variant: 'error', message: 'Sluttidspunkt ikke gyldig.'}));
                return;
            }
        }

        dispatch(
            plateCanPark({
                licensePlate: licensePlate.trim(),

                Uid: uid,
                phoneNumber: phoneNumber !== '' ? selected.countryCode + phoneNumber : '',
                email: '',
            })
        ).then(v => {
            if (v.payload === 'Plate can park') {
                dispatch(
                    parkFromTablet({
                        licensePlate: licensePlate.trim(),
                        Uid: uid,
                        phoneNumber: phoneNumber !== '' ? selected.countryCode + phoneNumber : '',
                        email: '',
                        delayedDate: delayedStartDate ?? '',
                        delayedDateTo: delayedEndDate ?? ''
                    })
                ).then(value => {
                    if (value.payload.data.ErrorMessage) {
                        dispatch(showMessage({variant: 'error', message: value.payload.data.ErrorMessage}));
                    } else {
                        navigate(`/${uid}/confirm`);
                    }
                });
            } else if (v.payload.ErrorCode === 1008) {
                dispatch(
                    showMessage({
                        variant: 'error',
                        message: v.payload.ErrorMessage,
                    })
                );
            } else {
                dispatch(
                    showMessage({
                        variant: 'error',
                        message: t('Der er allerede registreret en P-tilladelse med dette registreringsnummer i dag'),
                    })
                );
            }
        });
    }

    return (
        <>
            {!keyboardIsShown && !isPhone ? (
                <>
                    <div>
                        <div className="w-full sm:mb-0 l:mb-20">
                            <div className="px-5 flex justify-center mt-10">
                                <p className="text-3xl lg:text-5xl sm:text-s">{tablet.Title}</p>
                            </div>
                            <div className="flex mb-12 mt-8 justify-center items-center text-md px-5 text-center">
                                <p className="w-4/5 md:w-4/5 break-words justify-center">{tablet.Description}</p>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex justify-center items-center">
                                <form autoComplete="off" className="w-full justify-center items-center">
                                    <div className="flex justify-center items-center mb-2">
                                        <div className="lg:w-2/5 md:w-3/5 w-4/5">
                                            <input
                                                value={licensePlate}
                                                onChange={event => handleChange(event)}
                                                className="bg-white text-xs md:text-xl font-bold p-4 appearance-none border-none shadow-md rounded w-full focus:outline-blue text-gray-700"
                                                id="inline-full-name"
                                                type="text"
                                                placeholder={t('Indtast registreringsnummer')}
                                                onKeyDown={validateOnKeyDownInput}
                                                onPaste={validateOnPasteInput}
                                                maxLength={9}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex mb-6 justify-center items-center text-sm">
                                        <p>{t('Indtast kun bogstaver og tal')}</p>
                                    </div>
                                    <div className="flex justify-center items-center mb-2 md:mt-20 mt-0">
                                        <Listbox value={selected} onChange={setSelected}>
                                            <div
                                                className="flex lg:w-2/5 md:w-3/5 w-3/5 w-4/5 items-center bg-white appearance-none border-none shadow-md rounded">
                                                <Listbox value={selected} onChange={setSelected}>
                                                    <div className="relative mt-1">
                                                        <Listbox.Button
                                                            className="flex items-center relative w-full pl-3 pr-10 text-left cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                                             <span className="pr-2">
                                                <img alt="lang" width={40} height={20} src={selected.icon}/>
                                             </span>
                                                            <span
                                                                className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true"/>
                                             </span>
                                                        </Listbox.Button>
                                                        <Transition
                                                            as={Fragment}
                                                            leave="transition ease-in duration-100"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                        >
                                                            <Listbox.Options
                                                                className="absolute w-full z-50 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                {countryFlags.map((person, personIdx) => (
                                                                    <Listbox.Option
                                                                        key={personIdx}
                                                                        className={({active}) =>
                                                                            `${
                                                                                active ? 'bg-red-200' : ''
                                                                            } cursor-pointer select-none py-2 flex justify-center `
                                                                        }
                                                                        value={person}
                                                                    >
                                                                        {() => (
                                                                            <>
                                                            <span className="">
                                                               <img
                                                                   alt="sprog"
                                                                   width={40}
                                                                   height={40}
                                                                   src={person.icon}
                                                               />
                                                            </span>
                                                                            </>
                                                                        )}
                                                                    </Listbox.Option>
                                                                ))}
                                                            </Listbox.Options>
                                                        </Transition>
                                                    </div>
                                                </Listbox>
                                                <div
                                                    className="text-xs md:text-xl appearance-none font-bold py-4 bg-transparent border-0 rounded text-gray-700 px-1 focus:outline-blue active:outline-none">
                                       <span className="ml-2 md:text-xl opacity-80">
                                          00{selected.countryCode}
                                       </span>
                                                </div>
                                                <input
                                                    value={phoneNumber}
                                                    onChange={event => setPhoneNumber(event.target.value)}
                                                    className="text-xs md:text-xl appearance-none font-bold py-4 bg-transparent border-0 md:w-full rounded text-gray-700 px-2 focus:outline-blue active:outline-none"
                                                    type="tel"
                                                    placeholder={t('Indtast mobilnummer (valgfri)')}
                                                    aria-label="phonenumber"
                                                    onKeyDown={validateOnKeyDownInput}
                                                    onPaste={validateOnPasteInput}
                                                />
                                            </div>
                                        </Listbox>
                                    </div>

                                    <div className="flex mb-6 md:mb-12 justify-center items-center text-sm">
                                        <p>{t('Indtast mobilnummer og få en kvittering')}</p>
                                    </div>
                                    <div>
                                        {tablet.AllowDelayedValidFrom && (
                                            <div
                                                className="flex flex-row gap-2 my-12 justify-center items-center font-bold underline cursor-pointer"
                                            >
                                                <label>Vælg starttidspunkt:</label>
                                                <input
                                                    required={true}
                                                    type="datetime-local"
                                                    value={delayedStartDate}
                                                    min={new Date().toISOString().substring(0, 16)}
                                                    max={delayedEndDate ? delayedEndDate : undefined}
                                                    onChange={ev => setDelayedStartDate(ev.target.value)}
                                                    style={{color: '#07395c', outlineColor: '#07395c'}}
                                                    className="form-checkbox rounded p-3"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        {tablet.AllowDelayedValidTo && (
                                            <div
                                                className="flex flex-row gap-2 my-12 justify-center items-center font-bold underline cursor-pointer"
                                            >
                                                <label>Vælg sluttidspunkt:</label>
                                                <input
                                                    required={true}
                                                    disabled={!delayedStartDate}
                                                    type="datetime-local"
                                                    value={delayedEndDate}
                                                    min={delayedStartDate}
                                                    onChange={ev => setDelayedEndDate(ev.target.value)}
                                                    style={{color: '#07395c', outlineColor: '#07395c'}}
                                                    className="form-checkbox rounded p-3 disabled:opacity-50"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex mb-6 mb-0 md:mt-10 mt-0 justify-center">
                                        <label className="inline-flex items-center">
                                            <input
                                                type="checkbox"
                                                checked={acceptTerms}
                                                onClick={ev => setAcceptTerms(ev.target.checked)}
                                                style={{color: '#07395c', outlineColor: '#07395c'}}
                                                className="form-checkbox rounded p-3"
                                            />
                                            <span className="ml-2 text-lg">
                                    {' '}
                                                {t('Jeg accepterer')}{' '}
                                                <TermsModal text={t('vilkår og betingelser')} type={0}
                                                            language={selected}/>
                                 </span>
                                        </label>
                                    </div>
                                    <div
                                        className="flex mb-12 mt-6 justify-center items-center text-sm px-5 text-center">
                                        <p className="w-4/5 md:w-4/5 break-words">
                                            {t('GDPR')} <TermsModal text={t('GDPRLink')} type={1} language={selected}/>
                                        </p>
                                    </div>
                                    <div className="flex mb-6 justify-center items-center">
                                        <button
                                            // disabled={tablet.AllowDelayedValidFrom ? !delayedStartDate || !delayedEndDate || !canContinue : !canContinue}

                                            disabled={tablet.AllowDelayedValidFrom ? !delayedStartDate || tablet.AllowDelayedValidTo && !delayedEndDate || !canContinue : !canContinue}

                                            style={{backgroundColor: '#07395c'}}
                                            className="l:w-2/5 md:w-2/5 w-2/5 bg-lightBlue-500 disabled:opacity-50 shadow-md text-white font-bold uppercase px-8 py-3 rounded hover:shadow-lg outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => Continue()}
                                        >
                                            {t('Fortsæt')}
                                        </button>
                                    </div>
                                    {tablet.ParkingOverviewLink && (
                                        <div
                                            className="flex flex-row gap-2 my-12 justify-center items-center font-bold underline cursor-pointer"
                                            onClick={() => setShowParkingOverview(true)}
                                        >
                                            <img alt="OverviewIcon" src={overviewIcon}/>
                                            <p>{t('Få overblik over vores parkeringsmuligheder')}</p>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div>
                        <div className="w-full mb-6">
                            <div className="px-5 flex justify-center mt-10">
                                <p className=" text-3xl lg:text-5xl">{tablet.Title}</p>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="flex justify-center items-center">
                                <form autoComplete="off" className="w-full justify-center items-center">
                                    <div className="flex justify-center items-center mb-2">
                                        <div className="lg:w-3/5 md:w-4/5 w-4/5">
                                            <input
                                                value={licensePlate}
                                                onChange={event => handleChange(event)}
                                                className="text-xs md:text-xl bg-white font-bold p-4 appearance-none border-none shadow-md rounded w-full focus:outline-blue text-gray-700"
                                                id="inline-full-name"
                                                type="text"
                                                placeholder={t('Indtast registreringsnummer')}
                                                onKeyDown={validateOnKeyDownInput}
                                                onPaste={validateOnPasteInput}
                                                maxLength={9}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex mb-6 justify-center items-center text-sm">
                                        <p>{t('Indtast kun bogstaver og tal')}</p>
                                    </div>
                                    <div className="flex justify-center items-center mb-2">
                                        <Listbox value={selected} onChange={setSelected}>
                                            <div
                                                className="flex lg:w-3/5 md:w-4/5 w-4/5 items-center bg-white appearance-none border-none shadow-md rounded-md">
                                                <Listbox value={selected} onChange={setSelected}>
                                                    <div className="relative mt-1">
                                                        <Listbox.Button
                                                            className="flex items-center relative w-full z-50 py-2 mt-1 pl-3 pr-10 bg-white text-left cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                                             <span className="">
                                                <img alt="lang" width={40} height={20} src={selected.icon}/>
                                             </span>
                                                            <span
                                                                className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true"/>
                                             </span>
                                                        </Listbox.Button>
                                                        <Transition
                                                            as={Fragment}
                                                            leave="transition ease-in duration-100"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                        >
                                                            <Listbox.Options
                                                                className="absolute w-full z-50 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                {countryFlags.map((person, personIdx) => (
                                                                    <Listbox.Option
                                                                        key={personIdx}
                                                                        className={({active}) =>
                                                                            `${
                                                                                active ? 'bg-red-200' : ''
                                                                            } cursor-pointer select-none py-2 flex justify-center `
                                                                        }
                                                                        value={person}
                                                                    >
                                                                        {() => (
                                                                            <>
                                                            <span className="">
                                                               <img
                                                                   alt="sprog"
                                                                   width={40}
                                                                   height={40}
                                                                   src={person.icon}
                                                               />
                                                            </span>
                                                                            </>
                                                                        )}
                                                                    </Listbox.Option>
                                                                ))}
                                                            </Listbox.Options>
                                                        </Transition>
                                                    </div>
                                                </Listbox>
                                                <div
                                                    className="text-xs md:text-xl appearance-none font-bold py-4 bg-transparent border-0 rounded text-gray-700 px-1 focus:outline-blue active:outline-none">
                                       <span className="ml-2 md:text-xl opacity-80">
                                          00{selected.countryCode}
                                       </span>
                                                </div>
                                                <input
                                                    value={phoneNumber}
                                                    onChange={event => setPhoneNumber(event.target.value)}
                                                    className="text-xs md:text-xl appearance-none font-bold py-4 bg-transparent border-0 md:w-full rounded text-gray-700 px-2 focus:outline-blue active:outline-none"
                                                    type="tel"
                                                    placeholder={t('Indtast mobilnummer (valgfri)')}
                                                    aria-label="phonenumber"
                                                />
                                            </div>
                                        </Listbox>
                                    </div>

                                    <div className="flex mb-12 justify-center items-center text-sm">
                                        <p>{t('Indtast mobilnummer og få en kvittering')}</p>
                                    </div>

                                    <div>
                                        {tablet.AllowDelayedValidFrom && (
                                            <div
                                                className="flex flex-row gap-2 my-12 justify-center items-center font-bold underline cursor-pointer text-xs"
                                            >
                                                <label>Vælg starttidspunkt:</label>
                                                <input
                                                    required={true}
                                                    type="datetime-local"
                                                    value={delayedStartDate}
                                                    min={new Date().toISOString().substring(0, 16)}
                                                    max={delayedEndDate ? delayedEndDate : undefined}
                                                    onChange={ev => setDelayedStartDate(ev.target.value)}
                                                    style={{color: '#07395c', outlineColor: '#07395c'}}
                                                    className="form-checkbox rounded p-3"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        {tablet.AllowDelayedValidTo && (
                                            <div
                                                className="flex flex-row gap-2 my-12 justify-center items-center font-bold underline cursor-pointer text-xs"
                                            >
                                                <label>Vælg sluttidspunkt:</label>
                                                <input
                                                    required={true}
                                                    disabled={!delayedStartDate}
                                                    type="datetime-local"
                                                    value={delayedEndDate}
                                                    min={delayedStartDate}
                                                    onChange={ev => setDelayedEndDate(ev.target.value)}
                                                    style={{color: '#07395c', outlineColor: '#07395c'}}
                                                    className="form-checkbox rounded p-3 disabled:opacity-50"
                                                />
                                            </div>
                                        )}
                                    </div>

                                    <div className="flex mb-6 mb-0 md:mt-10 mt-0 justify-center">
                                        <label className="inline-flex items-center">
                                            <input
                                                type="checkbox"
                                                checked={acceptTerms}
                                                onClick={ev => setAcceptTerms(ev.target.checked)}
                                                style={{color: '#07395c', outlineColor: '#07395c'}}
                                                className="form-checkbox rounded p-3"
                                            />
                                            <span className="ml-2 text-lg">
                                    {' '}
                                                {t('Jeg accepterer')}{' '}
                                                <TermsModal text={t('vilkår og betingelser')} type={0}
                                                            language={selected}/>
                                 </span>
                                        </label>
                                    </div>
                                    <div
                                        className="flex mb-12 mt-8 justify-center items-center text-sm px-5 text-center">
                                        <p className="w-4/5 break-words">
                                            {t('GDPR')} <TermsModal text={t('GDPRLink')} type={1} language={selected}/>
                                        </p>
                                    </div>
                                    <div className="flex mb-6 justify-center items-center">
                                        <button
                                            disabled={tablet.AllowDelayedValidFrom ? !delayedStartDate || tablet.AllowDelayedValidTo && !delayedEndDate || !canContinue : !canContinue}
                                            style={{backgroundColor: '#07395c'}}
                                            className="l:w-2/5 md:w-2/5 w-2/5 bg-lightBlue-500 disabled:opacity-50 shadow-md text-white font-bold uppercase px-8 py-3 rounded hover:shadow-lg outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => Continue()}
                                        >
                                            {t('Fortsæt')}
                                        </button>
                                    </div>
                                    {tablet.ParkingOverviewLink && (
                                        <div
                                            className="flex flex-row gap-2 my-12 justify-center items-center font-bold underline cursor-pointer"
                                            onClick={() => setShowParkingOverview(true)}
                                        >
                                            <img alt="OverviewIcon" src={overviewIcon}/>
                                            <p>{t('Få overblik over vores parkeringsmuligheder')}</p>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <ParkingOverviewModal
                showParkingOverview={showParkingOverview}
                setShowParkingOverview={setShowParkingOverview}
            />
        </>
    );
}
