import React, {useEffect, Fragment, useRef, useState} from "react";
import axios from "axios";
import {Dialog, Transition} from '@headlessui/react'
import {useSelector} from "react-redux";


export default function TermsModal(props) {
    const [open, setOpen] = useState(false)
    const cancelButtonRef = useRef(null)
    const language = useSelector(({language}) => language.language)

    useEffect(() => {
        if (open === true) {
            var url = "";
            switch (props.type) {
                case 0:
                    if (language.selectedLanguage.id === language.allLanguages[0].id) {
                        url = "https://mpapi-staging-guest.azurewebsites.net/v9/texts/terms/terms_guest_da";
                    } else {
                        url = "https://mpapi-staging-guest.azurewebsites.net/v9/texts/terms/terms_guest_en"
                    }
                    break;
                case 1:
                    if (language.selectedLanguage.id === language.allLanguages[0].id) {
                        url = "https://mpapi-staging-guest.azurewebsites.net/v9/texts/terms/gdpr_guest_da"
                    } else {
                        url = "https://mpapi-staging-guest.azurewebsites.net/v9/texts/terms/gdpr_guest_en"
                    }
                    break;

                default:
                    break;
            }
            if (url !== "") {
                axios.get(url).then((res) => {
                    if (res.status === 200) {
                        document.getElementById("contentArea").innerHTML = res.data;
                    }
                })
            }
        }
    }, [open])

    return (
        <>
            <button className="hover:font-bold cursor-pointer font-bold" type="button"
                    onClick={() => setOpen(true)} value="vilkår og betingelser" style={{color: "#07395c"}}>{props.text}
            </button>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef}
                        onClose={setOpen}>
                    <div
                        className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div
                                className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">

                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <div className="mt-2">
                                                <p id="contentArea" className="text-sm text-gray-500">
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        style={{backgroundColor: '#07395b'}}
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 button-blue text-base font-medium text-white hover:button-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:button-blue sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => setOpen(false)}
                                    >
                                        OK
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )

}