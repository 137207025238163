import DanishFlag from "../../Assets/danishFlag.png";
import AustriaFlag from "../../Assets/counties/austriaFlag.png";
import BelgiumFlag from "../../Assets/counties/belgiumFlag.png";
import BulgariaFlag from "../../Assets/counties/bulgariaFlag.png";
import CroatiaFlag from "../../Assets/counties/croatiaFlag.png";
import CzechRepublicFlag from "../../Assets/counties/czechRepublicFlag.png";
import EnglandFlag from "../../Assets/counties/englandFlag.png";
import EstoniaFlag from "../../Assets/counties/estoniaFlag.png";
import FinlandFlag from "../../Assets/counties/finlandFlag.png";
import FranceFlag from "../../Assets/counties/franceFlag.png";
import GermanyFlag from "../../Assets/counties/germanyFlag.png"
import GreeceFlag from "../../Assets/counties/greeceFlag.png";
import HungaryFlag from "../../Assets/counties/hungaryFlag.png";
import IcelandFlag from "../../Assets/counties/icelandFlag.png";
import IrelandFlag from "../../Assets/counties/irelandFlag.png";
import ItalyFlag from "../../Assets/counties/italyFlag.png";
import LatviaFlag from "../../Assets/counties/latviaFlag.png";
import LiechtensteinFlag from "../../Assets/counties/liechtensteinFlag.png";
import LithuaniaFlag from "../../Assets/counties/lithuaniaFlag.png";
import LuxembourgFlag from "../../Assets/counties/luxembourgFlag.png";
import MaltaFlag from "../../Assets/counties/maltaFlag.png";
import NetherlandsFlag from "../../Assets/counties/netherlandsFlag.png";
import NorthernIrelandFlag from "../../Assets/counties/northernIrelandFlag.png";
import NorwayFlag from "../../Assets/counties/norwayFlag.png";
import PolandFlag from "../../Assets/counties/polandFlag.png";
import PortugalFlag from "../../Assets/counties/portugalFlag.png";
import RomaniaFlag from "../../Assets/counties/romaniaFlag.png";
import SanMarioFlag from "../../Assets/counties/sanMarioFlag.png";
import ScotlandFlag from "../../Assets/counties/scotlandFlag.png";
import SlovakiaFlag from "../../Assets/counties/slovakiaFlag.png";
import SloveniaFlag from "../../Assets/counties/sloveniaFlag.png";
import SpainFlag from "../../Assets/counties/spainFlag.png";
import SwedenFlag from "../../Assets/counties/swedenFlag.png";
import SwitzerlandFlag from "../../Assets/counties/switzerlandFlag.png";
import WalesFlag from "../../Assets/counties/walesFlag.png";
import AmericanFlag from "../../Assets/counties/americanFlag.png";


export const countryFlags = [
    { id: 1, name: 'Denmark', icon: DanishFlag, countryCode: '45' },
    { id: 2, name: 'Austria', icon: AustriaFlag, countryCode: '43' },
    { id: 3, name: 'Belgium', icon: BelgiumFlag, countryCode: '32' },
    { id: 4, name: 'Bulgaria', icon: BulgariaFlag, countryCode: '359' },
    { id: 5, name: 'Croatia', icon: CroatiaFlag, countryCode: '385' },
    { id: 6, name: 'CzechRepublic', icon: CzechRepublicFlag, countryCode: '420' },
    { id: 7, name: 'England', icon: EnglandFlag, countryCode: '44' },
    { id: 8, name: 'Estonia', icon: EstoniaFlag, countryCode: '372' },
    { id: 9, name: 'Finland', icon: FinlandFlag, countryCode: '358' },
    { id: 10, name: 'France', icon: FranceFlag, countryCode: '33' },
    { id: 11, name: 'Germany', icon: GermanyFlag, countryCode: '49' },
    { id: 12, name: 'Greece', icon: GreeceFlag, countryCode: '30' },
    { id: 13, name: 'Hungary', icon: HungaryFlag, countryCode: '36' },
    { id: 14, name: 'Iceland', icon: IcelandFlag, countryCode: '354' },
    { id: 15, name: 'Ireland', icon: IrelandFlag, countryCode: '353' },
    { id: 16, name: 'Italy', icon: ItalyFlag, countryCode: '39' },
    { id: 17, name: 'Latvia', icon: LatviaFlag, countryCode: '371' },
    { id: 18, name: 'Liechtenstein', icon: LiechtensteinFlag, countryCode: '423' },
    { id: 19, name: 'Lithuania', icon: LithuaniaFlag, countryCode: '370' },
    { id: 20, name: 'Luxembourg', icon: LuxembourgFlag, countryCode: '352' },
    { id: 21, name: 'Malta', icon: MaltaFlag, countryCode: '356' },
    { id: 22, name: 'Netherlands', icon: NetherlandsFlag, countryCode: '31' },
    { id: 23, name: 'NorthernIreland', icon: NorthernIrelandFlag, countryCode: '44' },
    { id: 24, name: 'Norway', icon: NorwayFlag, countryCode: '47' },
    { id: 25, name: 'Poland', icon: PolandFlag, countryCode: '48' },
    { id: 26, name: 'Portugal', icon: PortugalFlag, countryCode: '351' },
    { id: 27, name: 'Romania', icon: RomaniaFlag, countryCode: '40' },
    { id: 28, name: 'SanMario', icon: SanMarioFlag, countryCode: '378' },
    { id: 29, name: 'Scotland', icon: ScotlandFlag, countryCode: '44' },
    { id: 30, name: 'Slovakia', icon: SlovakiaFlag, countryCode: '421' },
    { id: 31, name: 'Slovenia', icon: SloveniaFlag, countryCode: '386' },
    { id: 32, name: 'Spain', icon: SpainFlag, countryCode: '34' },
    { id: 33, name: 'Sweden', icon: SwedenFlag, countryCode: '46' },
    { id: 34, name: 'Switzerland', icon: SwitzerlandFlag, countryCode: '41' },
    { id: 35, name: 'Wales', icon: WalesFlag, countryCode: '44' },
    { id: 36, name: 'America', icon: AmericanFlag, countryCode: '1' }
]
