import React, { useCallback, useEffect, useState } from 'react';
import withReducer from '../store/withReducer';
import reducer from './store/index';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmParking, ConfirmVoucherParking } from './store/informationSlice';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import {showMessage} from "../features/store/messageSlice";

export function Confirm() {
   const { t } = useTranslation();
   const information = useSelector(({ information }) => information.information);
   const selectedLanguage = useSelector(({ language }) => language.language.selectedLanguage);
   const dispatch = useDispatch();
   const { uid } = useParams();
   const isVoucher = useSelector(({ information }) => information.information.isVoucher);
   const navigate = useNavigate();
   const [timeRemaining, setTimeRemaining] = useState(30);

   useEffect(() => {
      const interval = setInterval(() => {
         setTimeRemaining(prev => prev - 1);
      }, [1000]);

      return () => {
         clearInterval(interval);
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const goBack = useCallback(() => {
      if (isVoucher) {
         navigate('/Voucherparkering');
      } else {
         navigate('/' + information.parking.UId);
      }
   }, [dispatch, information.parking.UId, isVoucher, navigate]);

   useEffect(() => {
      if (timeRemaining === 0) goBack();
   }, [goBack, timeRemaining]);

   function Confirm() {
      if (isVoucher) {
         dispatch(
            ConfirmVoucherParking({
               Email: '',
               PhoneNumber: information.phoneNumber,
               VehicleRegistrationCountry: 'DK',
               AllAreas: information.voucher.AllAreas,
               VehicleRegistration: information.vehicle.registrationNumber,
               parkingAreas: information.voucher.ParkingAreas,
               StartTime: information.voucher.StartTime,
               EndTime: information.voucher.EndTime,
               groupId: information.voucher.GroupId ?? '',
               VoucherCode: information.voucher.VoucherCode,
               Lang: selectedLanguage.i18Name,
            })
         ).then(v => {
            const minumumVehicleRegistrationLength = 2;
            if(v.payload.vehicleRegistration.trimmed.length >= minumumVehicleRegistrationLength)
               navigate(`/receipt`);
            else
               dispatch(showMessage({ variant: 'error', message: 'P-tilladelsen kunne ikke oprettes. Forsøg igen eller kontakt APCOA' }));
         });
      } else {
         dispatch(
            ConfirmParking({
               email: '',
               PhoneNumber: information.phoneNumber,
               VehicleRegistrationCountry: 'DK',
               Duration: information.parking.Duration,
               VehicleRegistration: information.vehicle.registrationNumber,
               parkingAreas: information.parking.ParkingAreas,
               StartTime: information.parking.StartTime,
               EndTime: information.parking.EndTime,
               UId: information.parking.UId,
               Lang: selectedLanguage.i18Name,
            })
         ).then(v => {
            navigate(`/${uid}/receipt`);
         });
      }
   }

   function getDuration(minutes) {
      var hours = 0;
      var min = 0;
      while (minutes >= 60) {
         hours = hours + 1;
         minutes = minutes - 60;
      }
      min = minutes;
      if (hours == 0) {
         return minutes + ' ' + t('Minutter');
      }

      if (minutes <= 0) {
         return hours + ' ' + t('Time(r)');
      } else {
         return hours + ' ' + t('Time(r)') + ' ' + min + ' ' + t('Minutter');
      }
   }

   return (
      <>
         <div>
            <div className="w-full">
               <div className="flex justify-center mt-2 md:mt-10">
                  <p className="text-sm md:text-3xl lg:text-5xl">{t('Bekræft parkering')}</p>
               </div>
            </div>
            <div className="w-full">
               <div className="flex justify-center md:mt-10">
                  <p className="text-sm lg:text-2xl md:text-2xl">{t('Dine oplysninger')}</p>
               </div>
            </div>
            <div className="w-full bg-white mt-5 pt-5 mb-5 pb-5 border-t-2 border-b-2 border-apcoablue">
               <div className="flex justify-center">
                  <p className="px-2 md:px-0 lg:text-xl xs:text-base text-red-500 italic">
                     {t('Kontroller venligst at registreringsnummer og mobilnummer er korrekt indtastet')}
                  </p>
               </div>
               <div className="flex justify-center mt-5  mb-5 px-2 md:px-0">
                  <div className="flex grid grid-cols-2 md:gap-x-60 md:gap-x-0 gap-y-4">
                     <div className="">
                        <p className="lg:text-xl xs:text-base">{t('Registreringsnummer')}:</p>
                     </div>
                     <div className="justify-center">
                        <p className="justify-center font-bold">
                           {isVoucher
                              ? information.voucher.VehicleRegistration
                              : information.vehicle.registrationNumber}
                        </p>

                        {information.vehicle.brand ? (
                           <div>
                              <p className="lg:text-sm xs:text-xs justify-center font-bold">
                                 {information.vehicle.brand} {information.vehicle.typeName}{' '}
                                 {information.vehicle.variant}
                              </p>
                              <p className="lg:text-sm xs:text-xs justify-center">{t('Ikke din bil? Tryk tilbage')}</p>
                           </div>
                        ) : (
                           <p className="lg:text-sm xs:text-xs justify-center text-red-500">
                              {t('Fortsæt hvis korrekt indtastet')}
                           </p>
                        )}
                     </div>
                     <div className="">
                        <p className="lg:text-xl xs:text-base">{t('Mobilnummer')}:</p>
                     </div>
                     <div className="">
                        <p className="font-bold">
                           {isVoucher ? information.voucher.PhoneNumber : information.phoneNumber}
                        </p>
                     </div>
                  </div>
               </div>
            </div>

            <div className="w-full">
               <div className="flex justify-center mt-2 md:mt-20">
                  <p className="lg:text-2xl md:text-2xl">{t('Detaljer for parkering')}</p>
               </div>

               <div className="flex justify-center md:mt-5 mt-0">
                  {isVoucher && information.voucher.AllAreas ? (
                     <p className="lg:text-xl xs:text-base italic"></p>
                  ) : (
                     <p className="lg:text-xl xs:text-base italic">{t('Du er ved at oprette en parkering')}</p>
                  )}
               </div>
               {isVoucher ? (
                  <div className="flex justify-center mt-1 md:mt-5">
                     {!information.voucher.AllAreas ? (
                        <div className="flex justify-center mt-1 md:mt-5">
                           {information.voucher.ParkingAreas.map((area, index) => {
                              return (
                                 <p key={index} className="md:text-xl text-xs font-bold xs:text-base">
                                    {area.Address.toString()} - {t('Områdekode')} {area.ParkingAreaKey}
                                 </p>
                              );
                           })}
                        </div>
                     ) : null}
                  </div>
               ) : (
                  <div className="flex justify-center mt-0 md:mt-5">
                     {information.parking.ParkingAreas.map((area, index) => {
                        return (
                           <p key={index} className="md:text-xl text-xs font-bold xs:text-base">
                              {area.Address.toString()} - {t('Områdekode')} {area.ParkingAreaKey}
                           </p>
                        );
                     })}
                  </div>
               )}
               <div className="flex justify-center mt-0 md:mt-5">
                  <div className="grid grid-cols-2 gap-x-40 gap-y-4">
                     <div className="">
                        <p className="md:text-xl text-xs xs:text-base">{t('Starttidspunkt')}</p>
                     </div>
                     {isVoucher ? (
                        <div className="">
                           <p className="md:text-xl text-xs font-bold">
                              {information.voucher.StartTime
                                 ? moment(information.voucher.StartTime).format('DD-MM-yyyy HH:mm')
                                 : ''}
                           </p>
                        </div>
                     ) : (
                        <div className="">
                           <p className="md:text-xl text-xs font-bold">
                              {information.period.StartTime
                                 ? moment(information.period.StartTime).format('DD-MM-yyyy HH:mm')
                                 : ''}
                           </p>
                        </div>
                     )}
                     <div className="">
                        <p className="md:text-xl text-xs xs:text-base">{t('Sluttidspunkt')}</p>
                     </div>
                     {isVoucher ? (
                        <div className="">
                           <p className="md:text-xl text-xs font-bold">
                              {information.voucher.EndTime
                                 ? moment(information.voucher.EndTime).format('DD-MM-yyyy HH:mm')
                                 : ''}
                           </p>
                        </div>
                     ) : (
                        <div className="">
                           <p className="md:text-xl text-xs font-bold">
                              {information.period.EndTime
                                 ? moment(information.period.EndTime).format('DD-MM-yyyy HH:mm')
                                 : ''}
                           </p>
                        </div>
                     )}
                     <div className="">
                        <p className="md:text-xl text-xs xs:text-base"> {t('Gyldighed')}</p>
                     </div>

                     <div className="">
                        {isVoucher ? (
                           <p className="md:text-xl text-xs font-bold">{getDuration(information.period.Duration)}</p>
                        ) : (
                           <p className="md:text-xl text-xs font-bold">{getDuration(information.period.Duration)}</p>
                        )}
                     </div>
                  </div>
               </div>
               <div className="flex mb-0 md:mb-6 justify-center items-center mt-5 md:mt-20">
                  <button
                     style={{ backgroundColor: '#63901a' }}
                     className="text-sm md:text-lg w-3/5  md:w-2/5 bg-green-500 shadow-md text-white font-bold uppercase px-8 py-3 rounded hover:shadow-lg outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                     type="button"
                     onClick={() => Confirm()}
                  >
                     {t('Bekræft og opret')}
                  </button>
               </div>
               <div className="flex mb-6 justify-center items-center mt-0 md:mt-5">
                  <button
                     className="text-sm md:text-lg w-3/5 md:w-2/5 bg-grey text-color-apcoablue shadow-md font-bold uppercase px-8 py-3 rounded hover:shadow-lg outline-blue mr-1 mb-1 ease-linear transition-all duration-150"
                     type="button"
                     onClick={() => goBack()}
                  >
                     {`${t('Tilbage')} (${timeRemaining})`}
                  </button>
               </div>
            </div>
         </div>
      </>
   );
}

export default withReducer('information', reducer)(Confirm);
